import OpenHeader from "src/shared/components/OpenHeader";
import SetForgotPasswordContainer from "../containers/SetForgotPassword/SetForgotPasswor";

const SetForgotPassword = () => {
  return (
    <>
      <OpenHeader />
      <SetForgotPasswordContainer />
    </>
  );
};

export default SetForgotPassword;
