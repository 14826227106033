import Header from "src/shared/components/Header/Header";
import SetPasswordContainer from "../containers/SetPassword/SetPassword";

const SetPassword = () => {
  return (
    <>
      <Header />
      <SetPasswordContainer />
    </>
  );
};

export default SetPassword;
