import Header from "src/shared/components/Header/Header";
import EditProfileContainer from "../containers/EditProfile/EditProfile";

const EditProfile = () => {
  return (
    <>
      <Header />
      <EditProfileContainer />
    </>
  );
};

export default EditProfile;
