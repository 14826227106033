import OpenHeader from "src/shared/components/OpenHeader";
import SignupForm from "../containers/RegisterContainer/SIgnupForm";

const Register = () => {
  return (
    <>
      <OpenHeader />
      <SignupForm />
    </>
  );
};

export default Register;
