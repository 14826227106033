
import Header from "src/shared/components/Header/Header";
import ProductDetailContainer from "../containers/ProductDetail";

const ProductDetail = () => {
  return (
    <>
     <Header /> <ProductDetailContainer />
    </>
  );
};

export default ProductDetail;
