import OpenHeader from "src/shared/components/OpenHeader";
import LoginForm from "../containers/LoginContainer/LoginForm";

const Login = () => (
  <>
    <OpenHeader />
    <LoginForm />
  </>
);

export default Login;
