import OpenHeader from "src/shared/components/OpenHeader";
import ForgotPasswordContainer from "../containers/ForgotPassword/ForgotPassword";

const ForgotPassword = () => {
  return (
    <>
      <OpenHeader />
      <ForgotPasswordContainer />
    </>
  );
};

export default ForgotPassword;
